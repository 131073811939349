import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Fade from '@/components/common/fade';
import { Link } from 'gatsby';
import Author from './author';

const FeaturedPost = ({ data }) => {
  const {
    date,
    title,
    description,
    mainImageAlt,
    authors,
    tags,
    mainImage,
    slug,
  } = data;

  return (
    <Fade delay={0}>
      <div className="flex flex-col items-center gap-8 md:flex-row lg:gap-10">
        <div className="h-full md:basis-1/2">
          <Link to={`/blog/${slug.current}`}>
            <GatsbyImage
              alt={mainImageAlt}
              image={mainImage.asset.gatsbyImageData}
              className="rounded-lg"
            />
          </Link>
        </div>
        <div className="flex flex-col gap-4 md:basis-1/2">
          <span className="font-outfit text-xs font-light text-gray-500 md:text-sm">
            {date}
          </span>
          <Link to={`/blog/${slug.current}`} className="flex flex-col gap-4">
            <h2 className="0.38.38ation-300 text-2xl hover:text-purply-blue md:text-3xl lg:text-4xl">
              {title}
            </h2>
            <p className="font-outfit text-lg font-light text-gray-700 duration-300">
              {description}
            </p>
          </Link>

          <div className="-mx-4 flex flex-wrap items-center">
            {authors &&
              authors.map((author, idx) => (
                <Author author={author} key={idx} />
              ))}
          </div>
          <div className="flex gap-4">
            {tags.map((tag, index) => {
              return (
                <Link
                  to={`/blog/tags/${tag.slug.current}`}
                  key={index}
                  className="font-outfit inline-flex rounded-lg bg-magnolia-500 px-4 py-1 text-sm font-light text-gray-700 duration-300 hover:bg-fuchsia-300"
                >
                  {tag.name}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default FeaturedPost;

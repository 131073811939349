import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const Author = ({ author }) => {
  return (
    <div className="w-auto px-4 py-1">
      <div className="-mx-1 flex flex-row items-center">
        {author.image && (
          <div className="w-auto px-1">
            <GatsbyImage
              alt={author.name}
              objectFit="cover"
              image={author.image.asset.gatsbyImageData}
              className="h-11 w-11 rounded-full md:h-12 md:w-12"
              imgClassName="rounded-full"
            />
          </div>
        )}

        <div className="flex w-auto flex-col px-1">
          <span className="text-sm text-purply-blue md:text-base">
            {author.name}
          </span>
          <span className="text-xs font-light md:text-sm">{author.role}</span>
        </div>
      </div>
    </div>
  );
};

export default Author;

import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Layout from '@/components/common/layout';
import Container from '@/components/common/container';
import BlogCard from '@/components/blog/blog-card';
import FeaturedPost from '@/components/blog/featured-post';
import HubspotForm from '@/components/common/hubspot-newsletter';
import SEO from '@/components/seo';
import { useLocation } from '@reach/router';
import SearchFilter from '@/components/blog/search-filter';

const Blog = () => {
  const { pathname } = useLocation();

  const data = useStaticQuery(graphql`
    {
      allSanityBlogTags {
        nodes {
          name
          isHidden
          slug {
            current
          }
        }
      }
      allSanityBlogPosts(sort: { order: DESC, fields: date }) {
        nodes {
          _id
          title
          description
          date(formatString: "MMMM DD YYYY")
          isFeatured
          slug {
            current
          }
          author {
            name
            role
          }
          authors {
            name
            role
            image {
              asset {
                gatsbyImageData(width: 56)
              }
            }
          }
          tags {
            name
            isHidden
            slug {
              current
            }
          }
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          mainImageAlt
        }
      }
    }
  `);
  const tags = data.allSanityBlogTags.nodes;
  const posts = data.allSanityBlogPosts.nodes;
  const featuredPost = posts.filter((p) => p.isFeatured)[0] || posts[0];

  const [search, setSearch] = useState('');
  const [currentTags, setCurrentTags] = useState<{ [key: string]: boolean }>(
    {},
  );

  const currentTagKeys = Object.keys(currentTags);
  const filterPosts = () => {
    return posts
      .filter((p) => p._id !== featuredPost._id)
      .filter((post) => {
        const { title, tags } = post;

        // Filter by tag.isHidden property
        const isHidden = !!tags.find((tag) => tag.isHidden);
        if (isHidden) {
          return false;
        }

        const searchMatch = (title as string)
          ?.toLowerCase()
          ?.includes(search?.trim()?.toLowerCase() || '');

        if (currentTagKeys.length === 0 && searchMatch) {
          return true;
        }

        return (
          tags.some((tag) => currentTags[tag.slug?.current?.toLowerCase()]) &&
          searchMatch
        );
      });
  };

  const displayedPosts = filterPosts();

  return (
    <Layout>
      <div className="pb-14 md:pb-24 lg:pb-32">
        <Container>
          <div>
            <div className="mt-16 md:mt-20">
              <FeaturedPost data={featuredPost} />
            </div>

            <div className="my-10 h-[1px] w-full bg-gradient-to-r from-orangey-yellow via-reddish-magenta to-purply-blue opacity-50 md:my-12 lg:my-16" />

            <div className="">
              <div className="flex flex-col-reverse flex-wrap gap-10 lg:flex-row lg:flex-nowrap">
                <div>
                  <h2 className="mb-8 text-3xl lg:text-4xl">
                    {displayedPosts.length > 0
                      ? 'Recent Posts'
                      : 'No Matches Found'}
                  </h2>
                  {displayedPosts.length > 0 && (
                    <div className="grid shrink grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2 lg:grid-cols-3">
                      {posts.map((post, idx) => {
                        const isShown = !!displayedPosts.find(
                          (p) => p.slug?.current === post.slug?.current,
                        );
                        return (
                          <div className={isShown ? 'block' : 'hidden'}>
                            <BlogCard
                              data={post}
                              idx={idx}
                              key={`post-${idx}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="ml-auto shrink-0 grow basis-[250px] lg:max-w-[260px]">
                  <SearchFilter setSearch={setSearch} search={search} />
                  <h2 className="pt-4 text-xl md:text-3xl">All Tags</h2>

                  <div className="font-outfit mt-4 flex flex-wrap gap-3 font-light text-gray-700">
                    <button
                      onClick={() => setCurrentTags({})}
                      className={`flex rounded-[9px] bg-red-500 from-orangey-yellow via-reddish-magenta to-purply-blue p-[2px] ${
                        currentTagKeys.length === 0
                          ? 'bg-gradient-to-tr'
                          : 'bg-transparent'
                      }`}
                    >
                      <span className="whitespace-nowrap rounded-[7px] bg-magnolia-500 px-3 py-1 duration-300">
                        All
                      </span>
                    </button>
                    {tags
                      .filter((tag) => !tag.isHidden)
                      .sort((a, b) => {
                        const nameA = a?.name.toLowerCase();
                        const nameB = b?.name.toLowerCase();

                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                      })
                      .map(({ name, slug }) => {
                        return (
                          <button
                            onClick={() => {
                              const name = slug.current?.toLowerCase();
                              const newTags = {
                                ...currentTags,
                                [name]: currentTags[name] ? false : true,
                              };
                              const exists = currentTags[name];
                              if (exists) {
                                delete newTags[name];
                              }

                              setCurrentTags(newTags);
                            }}
                            className={`flex rounded-[9px] bg-red-500 from-orangey-yellow via-reddish-magenta to-purply-blue p-[2px] ${
                              currentTags[slug.current.toLowerCase()]
                                ? 'bg-gradient-to-tr'
                                : 'bg-transparent'
                            }`}
                          >
                            <span className="whitespace-nowrap rounded-[7px] bg-magnolia-500 px-3 py-1 duration-300">
                              {name}
                            </span>
                          </button>
                        );
                      })}
                  </div>

                  <div className="mt-7 hidden lg:block">
                    <h3 className="text-lg md:text-xl">
                      Subscribe to Newsletter
                    </h3>
                    <HubspotForm
                      portalId="23114811"
                      formId="ac81cc8d-b237-4680-b711-bfcb987db902"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Blog;

export const Head = () => <SEO title="Blog" description="Galileo Blog posts" />;

import { cn } from '@/utils/tailwindUtils';
import React from 'react';
import { HiOutlineX, HiSearch } from 'react-icons/hi';

const SearchFilter = ({
  className,
  search,
  setSearch,
}: {
  className?: string;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const handleChange = (e) => {
    const newValue = e.target.value;
    setSearch(newValue);
  };

  return (
    <div className={cn('flex flex-col items-start gap-4', className)}>
      <h2 className="text-3xl leading-[51px]">Search</h2>

      <div className="relative h-[38px] w-full min-w-[260px]">
        <input
          placeholder="Search the blog"
          className="h-full w-full rounded-md border border-solid border-[#CBD6E2] py-2 pl-4 pr-8 placeholder-[#A1A1A1] focus-visible:outline-[rgba(82,168,236,.8)]"
          type="text"
          value={search}
          onChange={handleChange}
        />
        {search ? (
          <button
            className="group absolute right-[11px] top-1/2 -translate-y-1/2"
            onClick={() => setSearch('')}
          >
            <HiOutlineX
              size={18}
              className="text-[#A1A1A1] hover:text-[hsl(0,0%,40%)]"
            />
          </button>
        ) : (
          <HiSearch
            size={18}
            className="absolute right-[11px] top-1/2 -translate-y-1/2 text-[#A093F9]"
          />
        )}
      </div>
    </div>
  );
};

export default SearchFilter;
